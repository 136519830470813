<script lang="ts" setup>
import type { BButtonProps } from 'bootstrap-vue-next'
import { computed } from 'vue'
import useNavigation from '~/src/UserInterface/App/composables/Navigation/useNavigation'

const props = withDefaults(
  defineProps<BButtonProps & { title?: string }>(),
  {
    pressed: undefined,
    activeClass: 'active',
    variant: 'primary',
    title: undefined,
  },
)

const { route } = useNavigation()
const disabled = computed(() => props.disabled || props.loading || (typeof props.to === 'string' && route.path === props.to))
</script>

<template>
  <b-button
    v-bind="{ ...$props, ...$attrs }"
    :disabled="disabled"
  >
    <template #loading>
      <slot />
      <ui-spinner
        small
        class="ms-2"
      />
    </template>

    <template
      v-for="(_, name) in $slots"
      #[name]="scope"
    >
      <slot
        :name
        v-bind="scope ?? {}"
      />
    </template>
  </b-button>
</template>
